import React, { useRef, useState, useEffect } from 'react'
import TitleBox from '../components/styledComponents/TitleBox'
import PaddingPaper from '../components/styledComponents/PaddingPaper'
import TopContainer from '../components/styledComponents/TopContainer'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const ContactUs = () => {
  const formRef = useRef(null)
  const cookieRef = useRef(null)
  useEffect(() => {
    cookieRef.current.value = document.cookie.substring(
      document.cookie.indexOf('=') + 1
    )
  }, [])

  const [nameField, setNameField] = useState({
    value: '',
    valid: true
  })
  const [emailField, setEmailField] = useState({
    value: '',
    valid: true
  })

  const validate = () => {
    if (
      nameField.value !== '' &&
      emailField.value !== '' &&
      emailField.value.includes('@') &&
      emailField.value.includes('.')
    ) {
      formRef.current.submit()
      alert(
        'Your message has been submitted successfully! Thanks for getting in touch, We`ll get back to you shortly!'
      )
    }
    if (nameField.value === '' || emailField.value === '') {
      nameField.value === '' && setNameField({ ...nameField, valid: false })
      emailField.value === '' && setEmailField({ ...emailField, valid: false })
      alert('Please fill all require fields!')
    } else if (
      !emailField.value.includes('@') ||
      !emailField.value.includes('.')
    ) {
      setEmailField({ ...emailField, valid: false })
      alert('Please enter a valid email!')
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <TopContainer>
        <PaddingPaper>
          <form
            action='https://tech.us6.list-manage.com/subscribe/post?u=bed55a879b836b1c329cf167b&amp;id=fee04d2d67'
            method='post'
            noValidate
            autoComplete='off'
            name='mc-embedded-subscribe-form'
            ref={formRef}
          >
            <TitleBox>
              <Typography
                variant='h4'
                component='h2'
                align='center'
                color='secondary'
              >
                Contact Our Team
              </Typography>
            </TitleBox>
            <Grid container flexDirection='column' rowSpacing={2}>
              <Grid item>
                <TextField
                  label='Name'
                  type='text'
                  name='FNAME'
                  error={!nameField.valid}
                  onChange={(e) =>
                    setNameField({ value: e.target.value, valid: true })
                  }
                  required
                  fullWidth
                  InputLabelProps={{ color: 'secondary' }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label='Company Email'
                  required
                  type='email'
                  name='EMAIL'
                  error={!emailField.valid}
                  onChange={(e) =>
                    setEmailField({ value: e.target.value, valid: true })
                  }
                  fullWidth
                  InputLabelProps={{ color: 'secondary' }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label='Company'
                  type='text'
                  name='MMERGE6'
                  fullWidth
                  InputLabelProps={{ color: 'secondary' }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label='Position'
                  type='text'
                  name='MMERGE2'
                  fullWidth
                  InputLabelProps={{ color: 'secondary' }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label='Enquiry'
                  type='text'
                  name='MMERGE7'
                  fullWidth
                  multiline
                  rows={5}
                  InputLabelProps={{ color: 'secondary' }}
                />
              </Grid>
              <Grid item>
                <TextField
                  ref={cookieRef}
                  type='text'
                  name='MMERGE8'
                  sx={{ display: 'none' }}
                />
              </Grid>
              <Grid item>
                <TextField
                  type='text'
                  value=''
                  name='b_4edf884debbd7c28cd29d8649_2b5595a518'
                  tabindex='-1'
                  sx={{ position: 'absolute', left: '-5000px' }}
                />
              </Grid>
              <Grid item alignSelf='center'>
                <Button
                  color='secondary'
                  variant='outlined'
                  onClick={() => validate()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </PaddingPaper>
      </TopContainer>
    </Box>
  )
}

export default ContactUs
